import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "wir-über-uns"
    }}>{`Wir über uns`}</h1>
    <h3 {...{
      "id": "schamotte--ofen--und-tonwarenfabrik-mutzschen"
    }}>{`Schamotte-, Ofen- und Tonwarenfabrik, Mutzschen`}</h3>
    <ul>
      <li parentName="ul">{`1855 – Gründung einer Schamotte- Ofen- und Tonwarenfabrik in Mutzschen, heute Böhlitzer Weg 5. Gleichzeitig wurde auch eine Schankwirtschaft mit Übernachtungsmöglichkeiten betrieben.`}</li>
      <li parentName="ul">{`1899 – Übernahme der Fabrik durch Edmund Berger am 06.03.1899. Der Geschäftsgegenstand wurde wie folgt beworben: „Herstellung und Lieferung von Ofenkacheln, Kachelöfen, Küchenmaschinen, landwirtschaftlicher Koch-, Heiz- und Dämpfanlagen, Mehrzimmerkachelofenheizungen, zentrale Frisch- und Umluftheizungen für Hallen, Kirchen und Landhäuser“. Zudem wurden Tonwaren jeder Art, eiserne Öfen, Herde, Kessel, Pfannen, sämtliche Eisenteile für die Ofenbranche, Fliesen, Wandplatten und Schamottesteine angeboten. Edmund Bergers Bruder Karl arbeitete später auch in der Firma mit.`}</li>
      <li parentName="ul">{`1930/31 – Bau eines Lagers für Kacheln und Eisenmaterial (heute Bürogebäude der Berger Haustechnik GmbH) im Böhlitzer Weg 2.`}</li>
      <li parentName="ul">{`1942 – Bau eines Büro- und Wohnhauses im Böhlitzer Weg 5.`}</li>
      <li parentName="ul">{`1946 – Edmund Berger verstirbt. Er hat das Unternehmen während zweier Weltkriege, während der Inflation und der Weltwirtschaftskrise geführt. Seine Frau Hilma Berger übernimmt die Geschäfte.`}</li>
      <li parentName="ul">{`1946 – Sohn Gerhard Berger kehrt aus dem zweiten Weltkrieg heim und tritt in die Firma ein. Unterstützt wird er dabei von seiner Ehefrau Berta Berger.`}</li>
      <li parentName="ul">{`1948 – Sohn Hellmut Berger tritt nach seiner Heimkehr ebenfalls in die Firma ein und erhält ebenso Unterstützung durch seine Ehefrau Dora Berger.`}</li>
      <li parentName="ul">{`1951 – Die beiden Söhne übernehmen am 01.01.51 die Firma. Gerhard Berger übernahm als Ofensetzermeister die Montage der Öfen bei den Endkunden, während sein Bruder Hellmut als Töpfer- und Ofensetzermeister für die Fabrikfertigung der Öfen und Kacheln verantwortlich war.`}</li>
      <li parentName="ul">{`1966 – Gerhards Sohn Siegmar Berger beendet am 08.07.1966 erfolgreich sein Studium zum Ingenieur Heizung/ Lüftung/ Sanitär in Erfurt. Zuvor absolvierte er eine Lehre als Ofensetzter.`}
        <br /><br />
      </li>
    </ul>
    <h3 {...{
      "id": "pgh-wärmetechnik-mutzschen"
    }}>{`PGH Wärmetechnik, Mutzschen`}</h3>
    <ul>
      <li parentName="ul">{`1970 – Gründung der PGH Wärmetechnik durch Gerhard Berger und Siegmar Berger. Geschäftsgegenstand war die Errichtung von Zentralwarmwasserheizungen, Kachelöfen, Luftheizungen, Fliesenlegearbeiten.`}</li>
      <li parentName="ul">{`1973 – Hellmut Berger stellt aus gesundheitlichen und Rentabilitätsgründen die Kachelofenproduktion ein. Er verstirbt 1983.`}</li>
      <li parentName="ul">{`1983 – Gerhard Berger verstirbt am 24.01.1983. Siegmar Berger führt die Geschäfte als PGH-Vorsitzender fort.`}</li>
      <li parentName="ul">{`1984 – Die PGH schafft einen Kultur- und Versammlungsraum im Böhlitzer Weg 5.`}</li>
      <li parentName="ul">{`1985 – Die ersten ROBOTRON-Computer werden angeschafft und beginnen die Abrechnung und Planung zu erleichtern. Büros werden neu geschaffen und Geschäftsbereiche umstrukturiert.`}
        <br /><br />
      </li>
    </ul>
    <h3 {...{
      "id": "berger-haustechnik-gmbh"
    }}>{`BERGER HAUSTECHNIK GMBH`}</h3>
    <ul>
      <li parentName="ul">{`1990 – Gründung der Berger Haustechnik GmbH am 01.10.1990. Neue Werkzeuge, Fahrzeuge und Computertechnik werden angeschafft und Mitarbeiter geschult. Der Geschäftsgegenstand wird um die Gewerke Sanitär, Schornsteinsanierung, Trockenbau erweitert. Das Ingenieurbüro Berger (IBB) plante und projektierte HLS-Anlagen.`}</li>
      <li parentName="ul">{`1991 – Doris Berger tritt in die Firma ein.`}</li>
      <li parentName="ul">{`1992 – Die neuen Büroräume im Böhlitzer Weg 2 mit Lager, Fahrzeughalle und Werkstatt werden nach Komplettumbau des alten Ofensetzerlagers bezogen.`}</li>
      <li parentName="ul">{`1994 – Berger Haustechnik GmbH wird Sponsor der Mutzschener Boxer.`}</li>
      <li parentName="ul">{`1994 – Siegmar Berger verstirbt am 27.06.1994 kurz nach Vollendung seines 50. Lebensjahres an Herzversagen. Seine Frau Doris Berger führt die Geschäfte fort.`}</li>
      <li parentName="ul">{`1994 – Sohn Thomas Berger tritt am 10.10.1994 als Sanitärinstallateur in die Firma ein und beginnt die Meisterschule mit 4-jährigem Abendstudium.`}</li>
      <li parentName="ul">{`1994 – Sohn Gerald Berger tritt am 01.11.1994 in die Firma ein und führt parallel dazu sein Studium zum Dipl.-Kfm. an der Friedrich-Alexander-Universität Erlangen-Nürnberg fort.`}</li>
      <li parentName="ul">{`1995 – 140-Jahrfeier Gründung der Firma mit großer Handwerksmesse, Ausstellung historischer Produkte auf dem Gelände der Berger Haustechnik GmbH.`}</li>
      <li parentName="ul">{`1995 – Die Berger Haustechnik GmbH wird mit dem „Goldenen Sachsen“, dem OSCAR für den Mittelstand geehrt. Die Auszeichnung würdigte postum die Leistungen von Siegmar Berger.`}</li>
      <li parentName="ul">{`1996 – Gerald Berger beendet sein Studium zum Dipl. Kfm. (univ.) und übernimmt in der Berger Haustechnik GmbH die Leitung der Baustellen, sowie die kaufmännische Projektbearbeitung.`}</li>
      <li parentName="ul">{`1996 – Der Geschäftsgegenstand wird um die Gewerke Lüftung und Kälte erweitert.`}</li>
      <li parentName="ul">{`1998 – Thomas Berger beendet seine Meisterschule und übernimmt in der Berger Haustechnik GmbH die Kalkulation und Planung der Projekte.`}</li>
      <li parentName="ul">{`1999 - 100-Jahre Familientradition Berger. Mittlerweile arbeitet die vierte Generation im Geschäft.`}</li>
      <li parentName="ul">{`2001 – Gerald Berger und Thomas Berger werden neben Doris Berger zu Geschäftsführern berufen. Damit übernehmen die Enkel von Gerhard Berger genau fünf Jahrzehnte nach dessen Geschäftsübernahme die persönliche Verantwortung.`}</li>
      <li parentName="ul">{`2004 – Die Berger Haustechnik GmbH erhält den Marketingpreis der MITGAS.`}</li>
      <li parentName="ul">{`2004 – Die Berger Haustechnik GmbH wird Sponsor der 2. Bundesliga Damenvolleyball VC- Muldental Grimma.`}</li>
      <li parentName="ul">{`2005 – 150-Jahrfeier der Gründung der Firma.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      